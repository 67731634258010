<template>
  <base-section id="404" space="0">
    <v-container class="fill-height px-4 py-12">
      <v-row justify="center">
        <v-col cols="10">
          <base-subheading class="success--text" :title="$t('thankYou')" />

          <base-subheading
            space="8"
            class="text-h6"
            :title="$t('requestMade')"
          />

          <v-row justify="center">
            <v-icon size="170" class="mx-auto mb-12" color="success">
              mdi-food
            </v-icon>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="11">
                  <base-btn block :to="`/menu`">{{
                    $t("returnToMenu")
                  }}</base-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "ThankYouSection",

  provide: {
    heading: { align: "center" },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "100vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
